/**
* Fonts
**/

// Inter
$Inter: "Inter";

//Marko One
$MarkoOne: "Marko One";

//Space Grotesk
$SpaceGrotesk: "Space Grotesk";
