@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";
@import "src/assets/scss/breakpoints";

.toastWrapper {
  display: flex;
  padding: 16px 22px;
  gap: 26px;
  align-items: flex-start;
  .iconWrapper {
    padding: 8px;
    display: flex;
    border-radius: 50%;
    &.success {
      background-color: $green-13;
    }
  }
  .messageBody {
    color: $black-8;
    .title {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
    .message {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.toastBody {
  padding: 0px;
}
.toast {
  padding: 0px;
  border-radius: 12px;
  width: 360px;
  margin: 0 auto 1rem auto;
  &.success {
    box-shadow: 0px 4px 16px 0px rgba(1, 73, 53, 0.15);
    border: 1px solid $green-17;
  }
}
