@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.interviewContainer {
  min-height: 100dvh;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  background: $gray-1;
  .interviewWrapper {
    max-width: 784px;
    width: 100%;
    @include responsive("smDown") {
      max-width: 640px;
    }
    @include responsive("xsDown") {
      max-width: 350px;
    }
  }
}
