@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";
@import "src/assets/scss/breakpoints";

.headerContainer {
  margin-bottom: 24px;
  text-align: center;
  .logoWrapper {
    margin-bottom: 23.9px;
    > svg {
      height: auto;
      width: 320px;
      @include responsive("smDown") {
        height: auto;
        width: auto;
      }
    }
    @include responsive("smDown") {
      margin-bottom: 16px;
    }
  }
  .title {
    color: $green-0;
    font-family: $SpaceGrotesk;
    font-size: 32px;
    font-weight: 600;
    @include responsive("smDown") {
      font-size: 24px;
      font-weight: 600;
    }
  }
  .content {
    color: $green-0;
    font-family: $Inter;
    font-weight: 400;
    @include responsive("xsDown") {
      font-size: 14px;
    }
    > span {
      color: $orange-0;
      font-weight: 500;
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .info {
    text-align: justify;
    padding-top: 20px;
    color: $black-1;
    font-family: $Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }
}
