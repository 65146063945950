@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";

.buttonStyling {
  cursor: pointer;
  background: $white-0;
  padding: 12.5px 32px;
  border-radius: 12px;
  color: $white-0;
  font-family: $Inter;
  font-size: 16px;
  font-weight: 500;
  border-color: transparent;

  > svg {
    & + * {
      margin-left: 8px;

      & + svg {
        margin-left: 8px;
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &:disabled,
  &.primary-fill:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background: $gray-2;
    color: $white-0;
    border-color: transparent;
  }

  &.primary-fill {
    background-color: $green-1;
  }

  &.primary-outline {
    border: 1px solid $green-1;
    background-color: $white-0;
    color: $green-1;
  }

  &.secondary-outline {
    border: 1px solid $gray-0;
    background-color: $white-0;
    color: $black-1;
  }

  &.primary-link {
    border: none !important;
    outline: none !important;
    background-color: transparent;
    color: $green-2;
  }
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
