@import "src/assets/scss/colors";
@import "src/index";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  display: flex;
  overflow: hidden;
  background: $gray-1;
  min-height: 100vh;
  .leftSection {
    position: fixed;
    @include responsive("smDown") {
      z-index: 999;
    }
  }
  .rightSection {
    width: 100%;
    .wrapper {
      width: 100%;
      padding-left: $sideBarWidth;
      &.noPadding {
        padding: 0px !important;
      }
      @include responsive("smDown") {
        padding-left: 0px;
      }
    }
  }
}
