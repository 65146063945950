@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-1;

  ::-webkit-scrollbar {
    display: none;
  }

  .wrapper {
    max-width: 784px;
    background-color: $white-0;
    max-height: 100dvh;
    overflow-y: scroll;
    width: 100%;
    padding: 40px 80px 64px 80px;
    border-radius: 16px;
    border: 1px solid rgba(219, 214, 208, 0.45);
    box-shadow: 0 4px 8.6px 0 rgba(0, 0, 0, 0.04);

    @include responsive("smDown") {
      max-width: 480px;
      padding: 20px 40px;
    }
    @include responsive("xsDown") {
      max-width: 350px;
      padding: 30px 20px;
      min-height: 400px;
    }
  }
}
