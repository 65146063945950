@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";
@import "src/index";
@import "src/assets/scss/breakpoints";

.container {
  .progressTracker {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: $orange-4;
    margin: 24px;
    border-radius: 16px;
    border: 1px solid $orange-0;
    gap: 8px;
    .badge {
      padding: 2px 8px;
      background-color: $orange-0;
      color: $white-0;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      width: fit-content;
      text-align: left;
      border-radius: 4px;
    }
    .count {
      color: $black-6;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
      .message {
        color: $black-6;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
      }
    }

    .upgradeBtn {
      padding: 10px 32px;
      border: 1px solid $orange-0;
      color: $orange-0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .lowerWrapper {
    display: flex;
    padding: 20px 0px 20px 24px;
    align-items: center;
    .iconWrapper {
      width: 20%;
      > img {
        width: 40px;
        height: 40px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .logo {
        width: 40px;
        height: 40px;
        border-radius: 16px;
        background-color: $green-14;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .infoWrapper {
      width: 80%;
      font-family: $Inter;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      cursor: pointer;
      .userName {
        color: $black-9;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
      }
      .userMail {
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $gray-28;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
