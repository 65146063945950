@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";
@import "src/index";
@import "src/assets/scss/breakpoints";

.wrapper {
  position: relative;
  .backDrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #0000007d;
    backdrop-filter: blur(4px);
  }
  .container {
    width: $sideBarWidth;
    height: 100dvh;
    border-right: 1px solid $gray-6;
    background: $white-0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &.close {
      animation: modalClose 0.5s;
    }
    &.open {
      animation: modalOpen 0.5s;
      backdrop-filter: blur(2px);
    }

    .upperContainer {
      text-align: center;
      .logoWrapper {
        padding: 32px 0px;
      }
      .sideNavWrapper {
        display: flex;
        flex-direction: column;
        padding: 0px 24px;

        .navItemWrapper {
          display: flex;
          padding: 10px 16px;
          justify-content: flex-start;
          gap: 12px;
          cursor: pointer;
          &.active {
            background-color: $white-2;
            border-radius: 10px;
          }
          .title {
            color: $black-1;
            font-family: $SpaceGrotesk;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            &.active {
              color: $green-0;
            }
          }
        }
      }
    }
  }
  .HamBurgerIcon {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 32px;
    left: 24px;
    @include responsive("xsDown") {
      left: 20px;
    }
  }
}
@keyframes modalOpen {
  0% {
    transform: translateX(-270px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes modalClose {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-270px);
  }
}
